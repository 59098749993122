<template>
  <el-row class="marshall-header">
    <el-col :span="8" class="title"> Marshall</el-col>

    <el-col :span="16" style="text-align: right; padding-top: 4px">
      <el-button
        v-for="(letter, key) in uniqueCrlChar"
        :key="key"
        :type="selectedLetter === letter ? 'primary' : ''"
        size="small"
        v-on:click="onSetLetter(letter)"
        >{{ letter }}
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    internetConnected: Boolean,
    lastUpdated: String,
    teeTimes: Array,
  },
  data() {
    return {
      selectedLetter: "",
    };
  },
  computed: {
    uniqueCrlChar() {
      const uniqueChars = new Set(); // Using a Set to store unique values

      this.teeTimes.forEach((flight) => {
        if (flight.crlChar) {
          uniqueChars.add(flight.crlChar);
        }
      });

      return Array.from(uniqueChars); // Convert the Set back to an array
    },
  },
  methods: {
    onSetLetter(letter) {
      this.selectedLetter = letter;
      this.$emit("setCrlFilter", letter);
    },
  },
};
</script>

<style lang="scss">
.btn-log-off {
  border-radius: 4px;
  background-color: #12263f;
  padding: 4px 8px;
  text-align: center;
  position: relative;
  color: white;
  float: left;
  margin-bottom: 4px;
  cursor: pointer;
}

.title {
  font-size: 28px;
}

.btn-refresh {
  border-radius: 4px;
  background-color: #12263f;
  padding: 4px 8px;
  text-align: center;
  position: relative;
  color: white;
  margin-bottom: 4px;
  float: right;
}

.label-connected {
  border-radius: 4px;
  padding: 2px 4px;
  position: relative;
  color: black;
  float: left;
  font-size: 11px;
}

.label-last-updated {
  border-radius: 4px;
  padding: 2px 4px;
  float: right;
  font-size: 11px;
  color: #6e84a3;
  text-align: right;
}
</style>
