<template>
  <el-row>
    <el-col>
      <div class="tee-time-header" style="display: flex">
        <div style="min-width: 50px; width: 10%; float: left">Start</div>
        <div
          style="min-width: 50px; width: 10%; float: left; text-align: center"
        >
          Hl
        </div>
        <div
          class="hide-on-md"
          style="min-width: 120px; width: 10%; float: left"
        >
          Baan
        </div>
        <div style="flex-grow: 1">Spelers</div>
        <div style="min-width: 50px; width: 10%; float: left">HCP</div>
      </div>
      <tee-time-line
        v-for="(teeTime, key) in teeTimeArray"
        :key="key"
        :teeTime="teeTime"
      ></tee-time-line>
    </el-col>
  </el-row>
</template>

<script>
export default {
  components: {
    TeeTimeLine: require("./TeeTimeLine.vue").default,
  },
  props: {
    teeTimes: Array,
    letter: String,
  },
  computed: {
    teeTimeArray() {
      if (this.letter.length > 0) {
        return this.teeTimes.filter((time) => time.crlChar === this.letter);
      }
      return this.teeTimes;
    },
  },
};
</script>

<style lang="scss">
.tee-time-header {
  overflow-x: scroll;
  font-weight: bold;
}

@media (max-width: 992px) {
  .hide-on-md {
    display: none;
  }
}
</style>
