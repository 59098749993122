<template>
  <el-container class="background-white">
    <el-main style="height: 100vh">
      <tee-times-menu
        :internetConnected="internetConnected"
        :lastUpdated="lastUpdated"
        :teeTimes="teeTimes"
        v-on:getTeeTimes="getTeeTimes"
        v-on:onLogout="onLogout"
        v-on:onSetDate="onSetDate"
        v-on:setCrlFilter="setCrlFilter"
      />
      <el-divider class="divider" />
      <tee-time-block :letter="letter" :teeTimes="teeTimes" />
      <teet-times-footer
        :teeTimes="teeTimes"
        v-on:getTeeTimes="getTeeTimes"
        v-on:onLogout="onLogout"
        v-on:onSetDate="onSetDate"
        v-on:setCrlFilter="setCrlFilter"
      />
    </el-main>
  </el-container>
</template>

<script>
export default {
  components: {
    TeetTimesFooter: require("../components/TeeTimes/TeetTimesFooter.vue")
      .default,
    TeeTimesMenu: require("../components/TeeTimes/TeetTimesMenu.vue").default,
    TeeTimeBlock: require("../components/TeeTimes/TeeTimeBlock.vue").default,
  },
  data() {
    return {
      teeTimes: [],
      letter: "",
      lastUpdated: "",
      internetConnected: true,
    };
  },
  methods: {
    async getTeeTimes(date) {
      try {
        this.teeTimes = await this.$http.get("flights2", {
          params: { date: date },
        });
        this.lastUpdated = new Date().toTimeString().substr(0, 5);
        this.internetConnected = true;
      } catch (error) {
        console.error(error);
        this.internetConnected = false;
      }
    },
    setCrlFilter(letter) {
      this.letter = letter;
    },
    onLogout() {
      this.$confirm("Wilt u uitloggen?", "Uitloggen", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nee",
        type: "warning",
      }).then(() => {
        this.$ls.clear();
        this.$router.push("/");
      });
    },
    onSetDate(date) {
      this.getTeeTimes(date);
    },
  },
};
</script>

<style lang="scss">
.background-white {
  background-color: white;
}

.divider {
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>
